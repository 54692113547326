/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DashInitalState } from 'dto/redux.action.dto';
import {
	CLEAR_DASHBOARD,
	GET_BUSINESS_INFO,
	GET_CLIENT,
	GET_CONTRACT,
	GET_CONTRACT_REUPLOAD,
	GET_KYC_DOC,
	REMOVE_CONTRACT,
	UPLOAD_CONTRACT,
	SET_CLIENT_LIST,
	SET_LOGS_LIST,
	SET_ADVANCE_LIST,
	SET_REPAYMENT_LIST,
	UPDATE_CLIENT_LIST,
	UPDATE_ADVANCE_LIST,
	UPDATE_LOGS_LIST,
	UPDATE_REPAYMENT_LIST,
	SET_LEAD_LIST,
	UPDATE_LEAD_LIST,
	SET_CSV_LIST,
	UPDATE_CSV_LIST,
	SET_REPAYMENT_CLIENT_LIST,
	UPDATE_REPAYMENT_CLIENT_LIST,
	SET_CLIENT_OFFER,
	UPDATE_CLIENT_OFFER,
	SET_MANUAL_REPAYMENT_CLIENT_LIST,
	UPDATE_MANUAL_REPAYMENT_CLIENT_LIST,
	SET_MANUAL_REPAYMENT_LIST,
	UPDATE_MANUAL_REPAYMENT_LIST,
	SET_ROLE_LIST,
	UPDATE_ROLE_LIST,
	SET_USERS_LIST,
	UPDATE_USERS_LIST,
} from '../types';

const initialState: DashInitalState = {
	status: null,
	stripeConnected: true,
	goCardLessConnected: null,
	documentSubmitted: true,
	getOffer: true,
	getDashboard: true,
	_id: '',
	firstName: '',
	lastName: '',
	phone: '+',
	email: '',
	companyName: '',
	password: '',
	otpPreference: '',
	agreement: true,
	website: '',
	businessInfo: {
		AGE: '',
		REV_MONTH: '',
		MARG: '',
		ECOMM_VAL_CL: '',
		GROWTH: '',
	},
	kyc: {
		firstName: '',
		lastName: '',
		companyName: '',
		businessRegNo: '',
		image: '#',
	},
	verificationId: '',
	passEligibilityTest: true,
	salt: '',
	createdAt: '',
	updatedAt: '',
	documents: {
		businessDoc: [],
	},
	contract: {
		_id: '',
		url: '',
		fileType: '',
		clientId: '',
		createdAt: '',
		updatedAt: '',
		offerId: '',
	},
	contractDocs: [],
	businessInfoDocs: [],
	contractReupload: [],
	kycDoc: [],
	clientData: null,
	clients: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
	repayments: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
	repaymentsClient: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
	manualRepaymentsClient: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
	manualRepayments: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
	offer: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
	advance: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
	logs: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
	visitors: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
	reports: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
	users: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
	roles: {
		page: 1,
		total: 0,
		data: [],
		loading: true,
	},
};

const dashBoardReducer = (
	state = initialState,
	action: { type: any; payload: any }
) => {
	switch (action.type) {
		case SET_CLIENT_LIST: {
			return {
				...state,
				clients: action.payload,
			};
		}

		case UPDATE_CLIENT_LIST: {
			return {
				...state,
				clients: {
					...state.clients,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case SET_ADVANCE_LIST: {
			return {
				...state,
				advance: action.payload,
			};
		}

		case UPDATE_ADVANCE_LIST: {
			return {
				...state,
				advance: {
					...state.advance,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case SET_LOGS_LIST: {
			return {
				...state,
				logs: action.payload,
			};
		}

		case UPDATE_LOGS_LIST: {
			return {
				...state,
				logs: {
					...state.logs,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case SET_REPAYMENT_LIST: {
			return {
				...state,
				repayments: action.payload,
			};
		}

		case UPDATE_REPAYMENT_LIST: {
			return {
				...state,
				repayments: {
					...state.repayments,
					[action.payload.key]: action.payload.value,
				},
			};
		}
		case SET_REPAYMENT_CLIENT_LIST: {
			return {
				...state,
				repaymentsClient: action.payload,
			};
		}

		case UPDATE_REPAYMENT_CLIENT_LIST: {
			return {
				...state,
				repaymentsClient: {
					...state.repaymentsClient,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case SET_MANUAL_REPAYMENT_LIST: {
			return {
				...state,
				manualRepayments: action.payload,
			};
		}

		case UPDATE_MANUAL_REPAYMENT_LIST: {
			return {
				...state,
				manualRepayment: {
					...state.manualRepayments,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case SET_MANUAL_REPAYMENT_CLIENT_LIST: {
			return {
				...state,
				manualRepaymentsClient: action.payload,
			};
		}

		case UPDATE_MANUAL_REPAYMENT_CLIENT_LIST: {
			return {
				...state,
				manualRepaymentsClient: {
					...state.repaymentsClient,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case SET_CLIENT_OFFER: {
			return {
				...state,
				offer: action.payload,
			};
		}

		case UPDATE_CLIENT_OFFER: {
			return {
				...state,
				offer: {
					...state.offer,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case SET_LEAD_LIST: {
			return {
				...state,
				visitors: action.payload,
			};
		}

		case UPDATE_LEAD_LIST: {
			return {
				...state,
				visitors: {
					...state.visitors,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case SET_CSV_LIST: {
			return {
				...state,
				reports: action.payload,
			};
		}

		case UPDATE_CSV_LIST: {
			return {
				...state,
				reports: {
					...state.reports,
					[action.payload.key]: action.payload.value,
				},
			};
		}
		case SET_USERS_LIST: {
			return {
				...state,
				users: action.payload,
			};
		}

		case UPDATE_USERS_LIST: {
			return {
				...state,
				users: {
					...state.users,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case SET_ROLE_LIST: {
			return {
				...state,
				roles: action.payload,
			};
		}

		case UPDATE_ROLE_LIST: {
			return {
				...state,
				roles: {
					...state.roles,
					[action.payload.key]: action.payload.value,
				},
			};
		}

		case GET_CLIENT: {
			return { ...state, clientData: { ...action.payload } };
		}
		//Upload contract
		case UPLOAD_CONTRACT: {
			return { ...state, contract: action.payload };
		}
		//Set Contract
		case GET_CONTRACT: {
			return { ...state, contractDocs: action.payload };
		}
		//Get Business Info
		case GET_BUSINESS_INFO: {
			return { ...state, businessInfoDocs: action.payload };
		}
		//Get contract reupload docs
		case GET_CONTRACT_REUPLOAD: {
			return { ...state, contractReupload: action.payload };
		}
		case GET_KYC_DOC: {
			return { ...state, kycDoc: action.payload };
		}
		case REMOVE_CONTRACT: {
			return {
				...state,
				contract: {
					_id: '',
					url: '',
					fileType: '',
					clientId: '',
					createdAt: '',
					updatedAt: '',
					offerId: '',
				},
			};
		}
		case CLEAR_DASHBOARD: {
			return {
				...initialState,
			};
		}

		default: {
			return { ...state };
		}
	}
};

export const RootState = initialState;
export default dashBoardReducer;
