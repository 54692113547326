// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV;
const SENTRY_TRACING_ORIGIN = process.env.NEXT_PUBLIC_SENTRY_TRACING_ORIGIN;

Sentry.init({
	dsn: SENTRY_DSN,
	environment: SENTRY_ENV,
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1.0,
	integrations: [new BrowserTracing(SENTRY_TRACING_ORIGIN)],

	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
});
