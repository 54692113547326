import 'public/css/antd.css';
import 'design/scss/globals.scss';
import React from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { persistedStore, store } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function MyApp({ Component, pageProps }: AppProps) {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistedStore}>
				<Head>
					<link rel="shortcut icon" href="/favicon.ico" />
					<link rel="icon" sizes="192x192" href="/logo192.png" />
				</Head>
				<Component {...pageProps} />
			</PersistGate>
		</Provider>
	);
}

export default MyApp;
