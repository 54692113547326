/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer } from 'redux';
import {
	BAD_REQ,
	ERROR,
	LOG_IN,
	LOG_OUT,
	REMOVE_HASH,
	SET_LOADING,
	STORE_LOGIN_INFO,
	UNAUTHORIZED_ACCESS,
	UPDATE_OTP_TIMER,
} from '../types';

interface AuthenticationInitialState {
	isLoggedIn: boolean;
	hashKey: string | undefined;
	loader: boolean;
	otpTimer: number;
	accessToken: string | undefined;
	email: string | undefined;
	loggedInUser: any | undefined;
}

// initial state
const initialState: AuthenticationInitialState = {
	isLoggedIn: false,
	hashKey: undefined,
	loader: false,
	otpTimer: 0,
	accessToken: undefined,
	email: undefined,
	loggedInUser: undefined,
};

// auth reducer switch case
const authReducer: Reducer = (state = initialState, action) => {
	switch (action.type) {
		// STORE_LOGIN_INFO CASE
		case STORE_LOGIN_INFO: {
			return {
				...state,
				hashKey: action.payload.hashKey,
				email: action.payload.email,
			};
		}

		//Remove hash
		case REMOVE_HASH:
			return {
				...state,
				hashKey: undefined,
				otpTimer: 0,
			};

		case UPDATE_OTP_TIMER:
			return {
				...state,
				otpTimer: action.payload,
				hashKey: state.otpTimer === 0 ? null : state.hashKey,
			};

		//Otp verify
		case LOG_IN:
			return {
				...state,
				isLoggedIn: true,
				hashKey: undefined,
				otpTimer: 0,
				accessToken: action.payload.accessToken,
				loggedInUser: action.payload.admin,
			};

		//Authentication Loading
		case SET_LOADING:
			return {
				...state,
				loader: action.payload,
			};

		// LOG_OUT CASE
		case LOG_OUT:
			return {
				...initialState,
			};

		// ERROR CASE
		case ERROR:
			return {
				...state,
				err: action.payload.message,
			};

		// UNAUTHORIZED_ACCESS CASE
		case UNAUTHORIZED_ACCESS:
			return {
				...state,
				err: action.payload.message,
			};

		// BAD_REQ CASE
		case BAD_REQ:
			return {
				...state,
				err: action.payload.message,
			};

		// DEFAULT CASE
		default:
			return state;
	}
};

export const RootState = initialState;
export default authReducer;
