/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer } from 'redux';
import { SET_DEPLOYMENT_KEY } from '../types';

interface SettingsInitialState {
	deploymentKey: string | null;
}

// initial state
const initialState: SettingsInitialState = {
	deploymentKey: null,
};

// settings reducer switch case
const settingsReducer: Reducer = (state = initialState, action) => {
	switch (action.type) {
		// SET_DEPLOYMENT_KEY CASE
		case SET_DEPLOYMENT_KEY: {
			return { ...state, deploymentKey: action.payload };
		}

		// DEFAULT CASE
		default:
			return state;
	}
};

export const RootState = initialState;
export default settingsReducer;
