/* eslint-disable no-underscore-dangle */
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/rootReducer';
import { ENV } from 'config';

const persistConfigs = {
	key: 'reducer',
	storage: storage,
};

const persistedReducer = persistReducer(persistConfigs, rootReducer);

interface Window {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	__REDUX_DEVTOOLS_EXTENSION__?: any;
}

declare let window: Window;

const middleware = [thunk];
const enhancers = [applyMiddleware(...middleware)];

if (ENV === 'dev') {
	const reduxDevtools =
		typeof window !== 'undefined' &&
		window.__REDUX_DEVTOOLS_EXTENSION__() &&
		window.__REDUX_DEVTOOLS_EXTENSION__();

	enhancers.push(reduxDevtools || compose);
}

export const store = createStore(persistedReducer, compose(...enhancers));
export const persistedStore = persistStore(store);
