// Auth Action Types
export const LOG_IN = 'LOG_IN';
export const STORE_LOGIN_INFO = 'STORE_LOGIN_INFO';
export const LOG_OUT = 'LOG_OUT';
export const REMOVE_HASH = 'REMOVE_HASH';
export const SET_LOADING = 'SET_LOADING';

export const GET_CLIENT = 'GET_CLIENT';
export const GET_CONTRACT = 'GET_CONTRACT';
export const UPLOAD_CONTRACT = 'UPLOAD_CONTRACT';
export const GET_BUSINESS_INFO = 'GET_BUSINESS_INFO';
export const GET_CONTRACT_REUPLOAD = 'GET_CONTRACT_REUPLOAD';
export const GET_KYC_DOC = 'GET_KYC_DOC';
export const REMOVE_CONTRACT = 'REMOVE_CONTRACT';

export const BAD_REQ = 'BAD_REQ';
export const UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS';
export const ERROR = 'ERROR';

export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';

export const SET_CLIENT_LIST = 'SET_CLIENT_LIST';
export const UPDATE_CLIENT_LIST = 'UPDATE_CLIENT_LIST';

export const SET_ADVANCE_LIST = 'SET_ADVANCE_LIST';
export const UPDATE_ADVANCE_LIST = 'UPDATE_ADVANCE_LIST';

export const SET_LOGS_LIST = 'SET_LOGS_LIST';
export const UPDATE_LOGS_LIST = 'UPDATE_LOGS_LIST';

export const SET_CLIENT_OFFER = 'SET_CLIENT_OFFER';
export const UPDATE_CLIENT_OFFER = 'UPDATE_CLIENT_OFFER';

export const SET_REPAYMENT_LIST = 'SET_REPAYMENT_LIST';
export const UPDATE_REPAYMENT_LIST = 'UPDATE_REPAYMENT_LIST';

export const SET_REPAYMENT_CLIENT_LIST = 'SET_REPAYMENT_CLIENT_LIST';
export const UPDATE_REPAYMENT_CLIENT_LIST = 'UPDATE_REPAYMENT_CLIENT_LIST';

export const SET_MANUAL_REPAYMENT_LIST = 'SET_MANUAL_REPAYMENT_LIST';
export const UPDATE_MANUAL_REPAYMENT_LIST = 'UPDATE_MANUAL_REPAYMENT_LIST';

export const SET_MANUAL_REPAYMENT_CLIENT_LIST =
	'SET_MANUAL_REPAYMENT_CLIENT_LIST';
export const UPDATE_MANUAL_REPAYMENT_CLIENT_LIST =
	'UPDATE_MANUAL_REPAYMENT_CLIENT_LIST';

export const SET_LEAD_LIST = 'SET_LEAD_LIST';
export const UPDATE_LEAD_LIST = 'UPDATE_LEAD_LIST';

export const SET_CSV_LIST = 'SET_CSV_LIST';
export const UPDATE_CSV_LIST = 'UPDATE_CSV_LIST';

export const UPDATE_OTP_TIMER = 'UPDATE_OTP_TIMER';

export const SET_DEPLOYMENT_KEY = 'SET_DEPLOYMENT_KEY';

export const SET_USERS_LIST = 'SET_USERS_LIST';
export const UPDATE_USERS_LIST = 'UPDATE_USERS_LIST';
export const SET_ROLE_LIST = 'SET_ROLE_LIST';
export const UPDATE_ROLE_LIST = 'UPDATE_ROLE_LIST';
